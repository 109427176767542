import { envioService } from "@/_services";

export const envios = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    enviosEtiqueta: {
      data: null,
      status: {},
    },
    enviosEtiquetaMail: {
      data: {},
      status: {},
    },
    enviosExcel: {
      data: null,
      status: {},
    },
    logisticaInversa: {
      data: null,
      status: {},
    },
    simularValoracion: {
      data: null,
      status: {},
    },
  },
  mutations: {
    getSimulacionValoracionRequest(state) {
      state.simularValoracion.status = {
        loading: true,
      };
    },
    cancelSimulacionValoracionRequest(state, error) {
      state.simularValoracion.data = [];
      state.simularValoracion.status = {
        loading: false,
        error,
      };
    },
    getSimulacionValoracionSuccess(state, env) {
      state.simularValoracion.data = env;
      state.simularValoracion.status = {
        loaded: true,
      };
    },

    logisticaInversaRequest(state) {
      state.logisticaInversa.status = {
        creating: true,
      };
    },
    logisticaInversaError(state, error) {
      state.logisticaInversa.data = null;
      state.logisticaInversa.status = {
        error,
      };
    },
    logisticaInversaSuccess(state, cliente) {
      state.logisticaInversa.data = cliente;
      state.logisticaInversa.status = {
        created: true,
      };
    },
    getEnviosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelEnviosRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        loading: false,
        error,
      };
    },
    getEnviosSuccess(state, env) {
      state.list.data = env;
      state.list.status = {
        loaded: true,
      };
    },
    envioEtiquetaRequest(state) {
      state.enviosEtiqueta.status = {
        loading: true,
      };
    },
    envioEtiquetaSuccess(state, pdf) {
      state.enviosEtiqueta.data = pdf;
      state.enviosEtiqueta.status = {
        loaded: true,
      };
    },
    envioEtiquetaError(state, error) {
      state.enviosEtiqueta.data = null;
      state.enviosEtiqueta.status = {
        error,
      };
    },
    envioEtiquetaMailRequest(state) {
      state.enviosEtiquetaMail.status = {
        loading: true,
      };
    },
    envioEtiquetaMailSuccess(state, response) {
      state.enviosEtiquetaMail.data = response;
      state.enviosEtiquetaMail.status = {
        loaded: true,
      };
    },
    envioEtiquetaMailError(state, error) {
      state.enviosEtiquetaMail.data = null;
      state.enviosEtiquetaMail.status = {
        error,
      };
    },
    envioExcelRequest(state) {
      state.enviosExcel.status = {
        loading: true,
      };
    },
    envioExcelSuccess(state, pdf) {
      state.enviosExcel.data = pdf;
      state.enviosExcel.status = {
        loaded: true,
      };
    },
    envioExcelError(state, error) {
      state.enviosExcel.data = null;
      state.enviosExcel.status = {
        error,
      };
    },
  },
  actions: {
    getAllEnvios({ commit }, req) {
      commit("getEnviosRequest");
      envioService
        .getAllEnvios(req.pagination, req.filters)
        .then((env) => {
          commit("getEnviosSuccess", env);
        })
        .catch((error) => {
          commit("cancelEnviosRequest", error);
        });
    },
    getValoracionSimular({ commit }, body) {
      commit("getSimulacionValoracionRequest");
      envioService
        .getValoracionSimular(body)
        .then((env) => {
          commit("getSimulacionValoracionSuccess", env);
        })
        .catch((error) => {
          commit("cancelSimulacionValoracionRequest", error);
        });
    },
    envioEtiqueta({ commit }, body) {
      commit("envioEtiquetaRequest");
      envioService
        .envioEtiquetaFranquicia(body)
        .then((pdf) => {
          commit("envioEtiquetaSuccess", pdf);
        })
        .catch((error) => {
          commit("envioEtiquetaError", error);
        });
    },
    envioEtiquetaMail({ commit }, body) {
      commit("envioEtiquetaMailRequest");
      envioService
        .envioEtiquetaMail(body)
        .then((pdf) => {
          commit("envioEtiquetaMailSuccess", pdf);
        })
        .catch((error) => {
          commit("envioEtiquetaMailError", error);
        });
    },
    envioExcel({ commit }, filters) {
      commit("envioExcelRequest");
      envioService
        .getEnviosAsExcel(filters)
        .then((excel) => {
          commit("envioExcelSuccess", excel);
        })
        .catch((error) => {
          commit("envioExcelError", error);
        });
    },
    logisticaInversa({ commit }, body) {
      commit("logisticaInversaRequest");
      envioService
        .logisticaInversa(body)
        .then((envio) => {
          commit("logisticaInversaSuccess", envio);
        })
        .catch((error) => {
          commit("logisticaInversaError", error);
        });
    },
  },
};
