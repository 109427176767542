import Vue from 'vue';
import VueRouter from "vue-router";
import { userService } from "@/_services";
// router setup
import routes from "./routes";

// plugin setup
Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login','/recover_password','/restore_login'];
  const adminRequired = ['/franquicia/usuarios'];
  const authRequired = !publicPages.includes(to.path);
  const user = JSON.parse(localStorage.getItem('user'));

  if (authRequired) {
    if (!user)
      return next('/login');
    /* else if(adminRequired.includes(to.path) && !user.roles.includes('ADMIN')){
      return next('/franquicia/forbbiden');
    } */
    else {
      const user_entity = user;
      if (!userService.esFranquicia(user_entity))
        return next('/login');
    }
  }

  next();
});

export { router };
